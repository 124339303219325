.todo {
  color: red;
}

.justified-text {
  text-align: justify;
}

.excerpt {
  margin-left: 30px;
  width: 74%;

  h1 {
    display: none;
  }
}
