@import "main";

// Grid system
@import 'bootstrap/_functions.scss';
@import 'bootstrap/_variables.scss';
@import 'bootstrap/_mixins.scss';
@import 'bootstrap/_grid.scss';
@import 'bootstrap/bootstrap-grid.scss';      // Optional! Without it e.g. centering columns doesn't work and less than 12 columns!

@import 'bootstrap/_buttons.scss';
@import 'bootstrap/_transitions.scss';

// To avoid destroying the layout when importing `bootstrap-grid`
*,
*:before,
*:after {
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}

.container .row *,
.container .row *:before,
.container .row *:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
